<template>
  <v-container id="store" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showNameError">
        {{ showErrorEmail }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showPhoneNumberError">
        {{ showErrorPhoneNumber }}
      </v-alert>

      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="9" md="9">
              <label>Enter Store Address</label>
              <v-text-field
                id="address"
                class="purple-input"
                v-model="address"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="3" md="3">
              <label>Delivery Radius</label>
              <v-text-field
                id="address"
                label="In Miles"
                class="purple-input"
                v-model="deliveryRadius"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Store Name"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Phone Number"
                class="purple-input"
                v-model="phone_number"
                :rules="inputRule"
                :disabled="onlyView"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                label="Timing"
                class="purple-input"
                v-model="timing"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-textarea
                label="Description"
                class="purple-input"
                v-model="description"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Building Name"
                v-model="building_name"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="State"
                v-model="state"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Country"
                v-model="country"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="latitude"
                v-model="latitude"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Longitude"
                class="purple-input"
                v-model="longitude"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Zip Code"
                v-model="zipcode"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>
            <v-col cols="6" md="6" >
              <v-checkbox
                v-model="isTobaccoStore"
                label="Is this a tobacco store?."
                hide-details
                :disabled="onlyView"
                style="color: red;"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" v-if="isTobaccoStore">
              <v-text-field
                label="Clover Merchant Id"
                class="purple-input"
                v-model="clover_merchant_id"
                :rules="inputRule"
                :disabled="onlyView"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{ onlyView == true ? "Back" : editStore == true ? "Update" : "Submit" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import _ from "lodash";
var GoogleAddressParser = /** @class */ (function () {
  function GoogleAddressParser(address_components) {
    this.address_components = address_components;
    this.address = {};
    this.parseAddress();
  }
  GoogleAddressParser.prototype.parseAddress = function () {
    if (!Array.isArray(this.address_components)) {
      throw Error("Address Components is not an array");
    }
    if (!this.address_components.length) {
      throw Error("Address Components is empty");
    }
    for (var i = 0; i < this.address_components.length; i++) {
      var component = this.address_components[i];
      if (this.isStreetNumber(component)) {
        this.address.street_number = component.long_name;
      }
      if (this.isStreetName(component)) {
        this.address.street_name = component.long_name;
      }
      if (this.isCity(component)) {
        this.address.city = component.long_name;
      }
      if (this.isCountry(component)) {
        this.address.country = component.long_name;
        this.address.country_code = component.short_name;
      }
      if (this.isState(component)) {
        this.address.state = component.short_name;
      }
      if (this.isPostalCode(component)) {
        this.address.postal_code = component.long_name;
      }
    }
  };
  GoogleAddressParser.prototype.isStreetNumber = function (component) {
    return component.types.includes("street_number");
  };
  GoogleAddressParser.prototype.isStreetName = function (component) {
    return component.types.includes("route");
  };
  GoogleAddressParser.prototype.isCity = function (component) {
    return component.types.includes("locality");
  };
  GoogleAddressParser.prototype.isState = function (component) {
    return component.types.includes("administrative_area_level_1");
  };
  GoogleAddressParser.prototype.isCountry = function (component) {
    return component.types.includes("country");
  };
  GoogleAddressParser.prototype.isPostalCode = function (component) {
    return component.types.includes("postal_code");
  };
  GoogleAddressParser.prototype.result = function () {
    return this.address;
  };
  return GoogleAddressParser;
})();
export default {
  components: { VueGoogleAutocomplete },
  props: ["id", "action"],
  data() {
    return {
      deliveryRadius: 6,
      editStore: null,
      name: "",
      isTobaccoStore: false,
      clover_merchant_id: "",
      building_name: "",
      city: "",
      state: "",
      country: "",
      country_code: "",
      address: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      description: "",
      rating: "",
      timing: "",
      phone_number: "",
      street_name: "",
      showError: false,
      showErrorData: null,
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any store"],
      stateRule: [(v) => (v || "").length <= 2 || "Only 2 Characters"],
      onlyView: false,
      loading: false,
      showPhoneNumberError: false,
      showNameError: false,
    };
  },
  methods: {
    getStoreData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.description = response.data.store.description;
            this.name = response.data.store.name;
            this.building_name = response.data.store.building_name;
            this.city = response.data.store.city;
            this.state = response.data.store.state;
            this.isTobaccoStore = response.data.store.is_tobacco_store;
            this.clover_merchant_id = response.data.store.clover_merchant_id;
            this.country = response.data.store.country;
            this.country_code = response.data.store.country_code;
            this.address = response.data.store.address;
            this.zipcode = response.data.store.postalCode;
            this.latitude = response.data.store.latitude;
            this.longitude = response.data.store.longitude;
            this.rating = response.data.store.rating;
            this.timing = response.data.store.timing;
            this.phone_number = response.data.store.phone_number;
            this.images = response.data.store.images;
            this.deliveryRadius = response.data.store.deliveryRadius;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submit() {
      this.showNameError = false;
      this.showPhoneNumberError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("name", this.name);
        formData.append("building_name", this.building_name);
        formData.append("address", this.address);
        formData.append("description", this.description);
        formData.append("city", this.city);
        formData.append("country", this.country);
        formData.append("country_code", this.country_code);
        formData.append("state", this.state);
        formData.append("is_tobacco_store", this.isTobaccoStore);
        formData.append("clover_merchant_id", this.clover_merchant_id);
        formData.append("postalCode", this.zipcode);
        formData.append("timing", this.timing);
        formData.append("phone_number", this.phone_number);
        formData.append("latitude", this.latitude);
        formData.append("longitude", this.longitude);
        formData.append("deliveryRadius", this.deliveryRadius);
        formData.append("street", this.street_name);
        if (this.onlyView) {
          this.$router.push({ name: "Stores" });
        } else if (this.editStore == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "store/update", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Stores", params: { edit: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorEmail = error.response.data.name[0];
              }
              if (error.response.data.phone_number != null) {
                this.showPhoneNumberError = true;
                this.showErrorPhoneNumber = error.response.data.phone_number[0];
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "store/create", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Stores", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorEmail = error.response.data.name[0];
              }
              if (error.response.data.phone_number != null) {
                this.showPhoneNumberError = true;
                this.showErrorPhoneNumber = error.response.data.phone_number[0];
              }
            });
        }
      }
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getStoreData(this.id);
      this.editStore = true;
      this.selectProduct = true;
    } else {
      this.editStore = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }

    const places = new google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    google.maps.event.addListener(places, "place_changed", () => {
      var place = places.getPlace();
      this.address = place.formatted_address;
      if (place.geometry) {
        this.latitude = place.geometry["location"].lat();
        this.longitude = place.geometry["location"].lng();
        const formatted_result = new GoogleAddressParser(
          place.address_components
        ).result();
        this.building_name = place.name;
        this.country = formatted_result.country;
        this.state = formatted_result.state;
        this.street_name =
          formatted_result.street_number + " " + formatted_result.street_name;
        this.city = formatted_result.city;
        this.zipcode = formatted_result.postal_code;
        this.country_code = formatted_result.country_code;
      }
    });
  },
};
</script>
<style scoped>
#map {
  width: 100%;
}
</style>
